<template>
  <div class="manage-documents">
    <label @click="$router.push('/admin/home')" class="back">
      <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </figure>
      <p>Gerenciar documentos</p>
    </label>
    <div class="header">
      <label class="search" for="search-doc">
        <input autocomplete='off' type="text" placeholder="Digite sua busca" id="search-doc" name="search-doc" v-model="filter.search" @keydown.enter.prevent="getDocuments()">
        <svg v-if="!filter.search" @click="getDocuments()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.5002 17.5002L13.881 13.881M13.881 13.881C14.5001 13.2619 14.9912 12.527 15.3262 11.7181C15.6612 10.9093 15.8337 10.0423 15.8337 9.16684C15.8337 8.29134 15.6612 7.42441 15.3262 6.61555C14.9912 5.80669 14.5001 5.07174 13.881 4.45267C13.2619 3.8336 12.527 3.34252 11.7181 3.00748C10.9093 2.67244 10.0423 2.5 9.16684 2.5C8.29134 2.5 7.42441 2.67244 6.61555 3.00748C5.80669 3.34252 5.07174 3.8336 4.45267 4.45267C3.2024 5.70295 2.5 7.39868 2.5 9.16684C2.5 10.935 3.2024 12.6307 4.45267 13.881C5.70295 15.1313 7.39868 15.8337 9.16684 15.8337C10.935 15.8337 12.6307 15.1313 13.881 13.881Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else @click="filter.search = ''" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M4 4L16 16" stroke="#079F72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M16 4L4 16" stroke="#079F72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </label>
      <div class="filters">
        <button class="tooltip" @click="isDateFilterModalOpen = true;">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.6937 13.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.6937 16.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9945 13.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9945 16.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.29529 13.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.29529 16.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
        <button class="tooltip"  @click="$router.push('/admin/create-documents')">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
        <button class="tooltip" @click="isGlobalFiltersModalOpen = true;">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.9284 2.1001L5.99844 10.0001M5.39844 2.1001H18.5984C19.6984 2.1001 20.5984 3.0001 20.5984 4.1001V6.3001C20.5984 7.1001 20.0984 8.1001 19.5984 8.6001L15.2984 12.4001C14.6984 12.9001 14.2984 13.9001 14.2984 14.7001V19.0001C14.2984 19.6001 13.8984 20.4001 13.3984 20.7001L11.9984 21.6001C10.6984 22.4001 8.89844 21.5001 8.89844 19.9001V14.6001C8.89844 13.9001 8.49844 13.0001 8.09844 12.5001L4.29844 8.5001C3.79844 8.0001 3.39844 7.1001 3.39844 6.5001V4.2001C3.39844 3.0001 4.29844 2.1001 5.39844 2.1001Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
        <button v-if="documents?.data.length" class="tooltip" @click="downloadFiles(documents.data)">
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 11V17L11 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 17L7 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </figure>
        </button>
      </div>
    </div>
    <div class="order">
      <div v-if="user.hasRoles!(['Super Admin'])" :class="['tab', tab]">
        <h1 @click="tab = 'admin'">Administrador</h1>
        <h1 @click="tab = 'others'">Outros setores</h1>
        <span></span>
      </div>
      <div class="order-filter">
        <input
          type="radio"
          name="order-filter"
          id="order-filter-asc"
          value="DESC"
          @change="updateOrderFilter"
          checked
        />
        <input
          type="radio"
          name="order-filter"
          id="order-filter-desc"
          value="ASC"
          @change="updateOrderFilter"
        />
        <label for="order-filter-asc" class="asc">
          Mais novos
        </label>
        <label for="order-filter-desc" class="desc">
          Mais antigos
        </label>
      </div>
    </div>
    <div v-if="documents && documents?.data.length" class="documents">
      <div class="documents-title doc-grid">
        <label for="all-docs" class="check">
          <input type="checkbox" name="all-docs" id="all-docs" :checked="selectedDocuments.length == documents.data.length" @change="($event.target as HTMLInputElement).checked ? selectedDocuments = documents.data.map((d) => d) : selectedDocuments = []">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
              <path d="M5.06761 9.95167C4.94632 10.0727 4.78194 10.1407 4.61057 10.1407C4.43919 10.1407 4.27482 10.0727 4.15352 9.95167L0.501349 6.2995C0.411146 6.20946 0.339583 6.10251 0.290756 5.98479C0.241929 5.86706 0.216797 5.74086 0.216797 5.61341C0.216797 5.48596 0.241929 5.35976 0.290756 5.24203C0.339583 5.1243 0.411146 5.01736 0.501349 4.92732L0.958393 4.47132C1.14038 4.2894 1.38716 4.1872 1.64448 4.1872C1.9018 4.1872 2.14858 4.2894 2.33057 4.47132L4.61057 6.74923L10.7671 0.592713C10.9491 0.410791 11.1959 0.308594 11.4532 0.308594C11.7105 0.308594 11.9573 0.410791 12.1393 0.592713L12.5963 1.04976C12.6865 1.1398 12.7581 1.24674 12.8069 1.36447C12.8557 1.48219 12.8809 1.60839 12.8809 1.73584C12.8809 1.86329 12.8557 1.98949 12.8069 2.10722C12.7581 2.22495 12.6865 2.33189 12.5963 2.42193L5.06761 9.95167Z" fill="white"/>
            </svg>
          </span>
        </label>
        <label>
          <h1>Titulo e Origem</h1>
        </label>
        <label>
          <h1>Ticket/ofício</h1>
        </label>
        <label>
          <h1>Vencimento</h1>
        </label>
        <label>
          <h1>Responsável</h1>
        </label>
        <label>
          <h1>Status</h1>
        </label>
        <label>
          <h1>Ações</h1>
        </label>
      </div>
      <div class="documents-full" v-if="selectedDocuments.length">
        <svg @click="selectedDocuments = [];" class="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18 6L6 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h1>Ações em massa</h1>
        <h2><strong>{{ selectedDocuments.length }} itens foram selecionados</strong>, oque deseja fazer com todos eles?</h2>
        <div class="actions">
          <div @click="downloadFiles(selectedDocuments)">
            <p>baixar todos os anexos</p>
            <svg style="width: 24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="vuesax/linear/frame">
              <g id="frame">
              <path id="Vector" d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="Vector_2" d="M12 2V14.88" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="Vector_3" d="M15.3504 12.6504L12.0004 16.0004L8.65039 12.6504" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div v-for="document in (user.hasRoles!(['Super Admin']) ? documentsFromTab : documents.data)" :key="document.id" class="documents-list doc-grid">
        <label :for="`doc-${document.id}`" class="check">
          <input type="checkbox" :id="`doc-${document.id}`" :checked="selectedDocuments.find((d) => d.id == document.id) ? true : false" @change="selectedDocuments.find((d) => d.id == document.id) ? (selectedDocuments = selectedDocuments.filter((d: DocumentModel) => document != d)) : selectedDocuments.push(document)">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
              <path d="M5.06761 9.95167C4.94632 10.0727 4.78194 10.1407 4.61057 10.1407C4.43919 10.1407 4.27482 10.0727 4.15352 9.95167L0.501349 6.2995C0.411146 6.20946 0.339583 6.10251 0.290756 5.98479C0.241929 5.86706 0.216797 5.74086 0.216797 5.61341C0.216797 5.48596 0.241929 5.35976 0.290756 5.24203C0.339583 5.1243 0.411146 5.01736 0.501349 4.92732L0.958393 4.47132C1.14038 4.2894 1.38716 4.1872 1.64448 4.1872C1.9018 4.1872 2.14858 4.2894 2.33057 4.47132L4.61057 6.74923L10.7671 0.592713C10.9491 0.410791 11.1959 0.308594 11.4532 0.308594C11.7105 0.308594 11.9573 0.410791 12.1393 0.592713L12.5963 1.04976C12.6865 1.1398 12.7581 1.24674 12.8069 1.36447C12.8557 1.48219 12.8809 1.60839 12.8809 1.73584C12.8809 1.86329 12.8557 1.98949 12.8069 2.10722C12.7581 2.22495 12.6865 2.33189 12.5963 2.42193L5.06761 9.95167Z" fill="white"/>
            </svg>
          </span>
        </label>
        <label class="title">
          <h1>{{ document.title }}</h1>
          <h2>
            {{ document.creator.organization?.name }}
          </h2>
        </label>
        <label>
          <h1>{{ document.physical_document_number }}</h1>
          <h2>{{ user.tenant?.acronym }}{{ String(document.number).padStart(3, '0') }}</h2>
        </label>
        <label :class="{ late: false }" class="date">
          <h1 :class="document.status != 'overdue' ? '':'text-red'">{{ new Date(document.limit_date).toLocaleDateString() }}</h1>
          <span v-if="getDaysDiff(document.limit_date) > 0" class="tag black">em {{ getDaysDiff(document.limit_date) }} dias</span>
        </label>
        <label>
          <h1 v-if="document.responsibles">{{ document.responsibles[0].name }}</h1>
        </label>
        <label :class="{ exceptions: true }">
          <div class="tag purple" :class="document.status">{{ documentStatusTranslations[document.status] }}</div>
          <div class="exception" v-if="document.is_exception">
            exceção
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M9.99935 18.3332C14.5827 18.3332 18.3327 14.5832 18.3327 9.99984C18.3327 5.4165 14.5827 1.6665 9.99935 1.6665C5.41601 1.6665 1.66602 5.4165 1.66602 9.99984C1.66602 14.5832 5.41601 18.3332 9.99935 18.3332Z" stroke="#E1793E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 6.6665V10.8332" stroke="#E1793E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.99609 13.3335H10.0036" stroke="#E1793E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </label>
        <label for="" class="actions">
          <button class="tooltip" @click="$router.push({ name: 'admin.documents.show', params: { id: document.id } })">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill="none" d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M11.9998 20.2702C15.5298 20.2702 18.8198 18.1902 21.1098 14.5902C22.0098 13.1802 22.0098 10.8102 21.1098 9.40021C18.8198 5.80021 15.5298 3.72021 11.9998 3.72021C8.46984 3.72021 5.17984 5.80021 2.88984 9.40021C1.98984 10.8102 1.98984 13.1802 2.88984 14.5902C5.17984 18.1902 8.46984 20.2702 11.9998 20.2702Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
          </button>
          <!-- <button class="tooltip">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill="none" d="M18.4698 16.83L18.8598 19.99C18.9598 20.82 18.0698 21.4 17.3598 20.97L13.1698 18.48C12.7098 18.48 12.2599 18.45 11.8199 18.39C12.5599 17.52 12.9998 16.42 12.9998 15.23C12.9998 12.39 10.5398 10.09 7.49985 10.09C6.33985 10.09 5.26985 10.42 4.37985 11C4.34985 10.75 4.33984 10.5 4.33984 10.24C4.33984 5.68999 8.28985 2 13.1698 2C18.0498 2 21.9998 5.68999 21.9998 10.24C21.9998 12.94 20.6098 15.33 18.4698 16.83Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M13 15.2298C13 16.4198 12.56 17.5198 11.82 18.3898C10.83 19.5898 9.26 20.3598 7.5 20.3598L4.89 21.9098C4.45 22.1798 3.89 21.8098 3.95 21.2998L4.2 19.3298C2.86 18.3998 2 16.9098 2 15.2298C2 13.4698 2.94 11.9198 4.38 10.9998C5.27 10.4198 6.34 10.0898 7.5 10.0898C10.54 10.0898 13 12.3898 13 15.2298Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </figure>
          </button> -->
          <!-- TAG 'active' para abrir o menu -->
          <button :class="{active: true}" class="options" @click="selectedDocument = document">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26" fill="none">
              <circle stroke="none" cx="3" cy="3" r="3" transform="rotate(-90 3 3)"/>
              <circle stroke="none" cx="3" cy="13" r="3" transform="rotate(-90 3 13)"/>
              <circle stroke="none" cx="3" cy="23" r="3" transform="rotate(-90 3 23)"/>
            </svg>
            <section>
              <div id="confirm" v-if="isSelectedDocumentOpen && selectedDocumentAbilities.completeDocument" @click="isConfirmCompleteDocumentModal = true">
                <p>Concluir</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                  <g clip-path="url(#clip0_366_3431)">
                    <path d="M4.6487 9.24519C4.53246 9.36121 4.37493 9.42638 4.2107 9.42638C4.04646 9.42638 3.88894 9.36121 3.7727 9.24519L0.272696 5.74519C0.186251 5.6589 0.11767 5.55641 0.0708778 5.44359C0.0240854 5.33077 0 5.20983 0 5.08769C0 4.96555 0.0240854 4.84461 0.0708778 4.73178C0.11767 4.61896 0.186251 4.51648 0.272696 4.43019L0.710696 3.99319C0.885096 3.81885 1.1216 3.72091 1.3682 3.72091C1.61479 3.72091 1.8513 3.81885 2.0257 3.99319L4.2107 6.17619L10.1107 0.276188C10.2851 0.101846 10.5216 0.00390625 10.7682 0.00390625C11.0148 0.00390625 11.2513 0.101846 11.4257 0.276188L11.8637 0.714188C11.9501 0.800475 12.0187 0.902962 12.0655 1.01578C12.1123 1.12861 12.1364 1.24955 12.1364 1.37169C12.1364 1.49383 12.1123 1.61477 12.0655 1.72759C12.0187 1.84041 11.9501 1.9429 11.8637 2.02919L4.6487 9.24519Z" stroke='none'/>
                  </g>
                  <defs>
                    <clipPath id="clip0_366_3431">
                      <rect width="12.141" height="9.427" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="isSelectedDocumentOpen && selectedDocumentAbilities.transferDocument" @click="isTransferDocumentModalOpen = true">
                <p>Transferir</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M8.33268 5.00033H11.666C13.3327 5.00033 13.3327 4.16699 13.3327 3.33366C13.3327 1.66699 12.4993 1.66699 11.666 1.66699H8.33268C7.49935 1.66699 6.66602 1.66699 6.66602 3.33366C6.66602 5.00033 7.49935 5.00033 8.33268 5.00033Z" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.6667 18.3329H7.5C3.33333 18.3329 2.5 16.6663 2.5 13.3329V8.33294C2.5 4.53294 3.89167 3.49961 6.66667 3.34961" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.334 3.34961C16.109 3.49961 17.5007 4.52461 17.5007 8.33294V12.4996" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5 15.833V18.333H15" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.5 13.333L17.4667 18.2997" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div @click="downloadFiles([document])">
                <p>Baixar anexo</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_366_3443)">
                    <path d="M7.5 9.16699V14.167L9.16667 12.5003" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.50065 14.1667L5.83398 12.5" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.3327 8.33366V12.5003C18.3327 16.667 16.666 18.3337 12.4993 18.3337H7.49935C3.33268 18.3337 1.66602 16.667 1.66602 12.5003V7.50033C1.66602 3.33366 3.33268 1.66699 7.49935 1.66699H11.666" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.3327 8.33366H14.9993C12.4993 8.33366 11.666 7.50033 11.666 5.00033V1.66699L18.3327 8.33366Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_366_3443">
                      <rect width="20" height="20" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="selectedDocumentAbilities.reopenDocument" @click="isConfirmCompleteDocumentModal = true">
                <p>Reabrir</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M14.166 18.333H5.83268C2.49935 18.333 1.66602 17.4997 1.66602 14.1663V12.4997C1.66602 9.16634 2.49935 8.33301 5.83268 8.33301H14.166C17.4993 8.33301 18.3327 9.16634 18.3327 12.4997V14.1663C18.3327 17.4997 17.4993 18.333 14.166 18.333Z" fill='none' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5 8.33366V6.66699C5 3.90866 5.83333 1.66699 10 1.66699C13.75 1.66699 15 3.33366 15 5.83366" fill='none' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.99935 15.4167C11.1499 15.4167 12.0827 14.4839 12.0827 13.3333C12.0827 12.1827 11.1499 11.25 9.99935 11.25C8.84876 11.25 7.91602 12.1827 7.91602 13.3333C7.91602 14.4839 8.84876 15.4167 9.99935 15.4167Z" fill='none' stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </section>
          </button>
        </label>
      </div>
      <!-- pagination  -->
      <div class="pagination" v-if="documents.links.length > 3">
        <button
          :disabled="(index == 0 && !documents.prev_page_url) || (index == documents.links.length - 1 && !documents.next_page_url)"
          @click="getDocuments(index != 0 && (index != documents.links.length - 1) ? parseInt(link.label) : (documents.current_page + (index ? 1 : -1)) )"
          v-for="(link, index) in documents.links"
          :key="index"
          :class="{active: link.active, passers: index == 0 || index == documents.links.length - 1}"
        >
          {{ link.label }}
        </button>
      </div>
      <!-- pagination  -->
    </div>
    <div class="no-options" v-else-if="documents && !documents.data.length">
      <svg xmlns="http://www.w3.org/2000/svg" width="85" height="84" viewBox="0 0 25 24" fill="none">
        <path opacity="0.4" d="M21 10.19H18.11C15.74 10.19 13.81 8.26 13.81 5.89V3C13.81 2.45 13.36 2 12.81 2H8.57C5.49 2 3 4 3 7.57V16.43C3 20 5.49 22 8.57 22H16.43C19.51 22 22 20 22 16.43V11.19C22 10.64 21.55 10.19 21 10.19Z" fill="#171717"/>
        <path d="M16.2999 2.20999C15.8899 1.79999 15.1799 2.07999 15.1799 2.64999V6.13999C15.1799 7.59999 16.4199 8.80999 17.9299 8.80999C18.8799 8.81999 20.1999 8.81999 21.3299 8.81999C21.8999 8.81999 22.1999 8.14999 21.7999 7.74999C20.3599 6.29999 17.7799 3.68999 16.2999 2.20999Z" fill="#171717"/>
        <path d="M14 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H14C14.41 12.25 14.75 12.59 14.75 13C14.75 13.41 14.41 13.75 14 13.75Z" fill="#171717"/>
        <path d="M12 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H12C12.41 16.25 12.75 16.59 12.75 17C12.75 17.41 12.41 17.75 12 17.75Z" fill="#171717"/>
      </svg>
      <h1>Sem documentos para gerenciar no momento</h1>
      <p><a @click="$router.push('/admin/create-documents')">Clique aqui</a> para cadastrar</p>
    </div>
    <SpinnerLoader v-else />
    <nav :class="{active: isMobileOptionsFilterOpen}" @click="toggleOverflow()" class="action-filters">
      <button @click.stop  @click="isDateFilterModalOpen = true; toggleOverflow();">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 2V5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.5 9.08984H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.6937 13.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.6937 16.7002H15.7027" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.9945 13.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.9945 16.7002H12.0035" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.29529 13.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.29529 16.7002H8.30427" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button @click.stop @click="$router.push('/admin/create-documents'); toggleOverflow();">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9.16602 1.6665H7.49935C3.33268 1.6665 1.66602 3.33317 1.66602 7.49984V12.4998C1.66602 16.6665 3.33268 18.3332 7.49935 18.3332H12.4993C16.666 18.3332 18.3327 16.6665 18.3327 12.4998V10.8332" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3675 2.51688L6.80088 9.08354C6.55088 9.33354 6.30088 9.82521 6.25088 10.1835L5.89254 12.6919C5.75921 13.6002 6.40088 14.2335 7.30921 14.1085L9.81754 13.7502C10.1675 13.7002 10.6592 13.4502 10.9175 13.2002L17.4842 6.63354C18.6175 5.50021 19.1509 4.18354 17.4842 2.51688C15.8175 0.850211 14.5009 1.38354 13.3675 2.51688Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4258 3.4585C12.9841 5.45016 14.5424 7.0085 16.5424 7.57516" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button v-if="documents?.data.length" class="tooltip" @click="downloadFiles(documents.data)">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9 11V17L11 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 17L7 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button @click.stop @click="isGlobalFiltersModalOpen = true; toggleOverflow();">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10.9284 2.1001L5.99844 10.0001M5.39844 2.1001H18.5984C19.6984 2.1001 20.5984 3.0001 20.5984 4.1001V6.3001C20.5984 7.1001 20.0984 8.1001 19.5984 8.6001L15.2984 12.4001C14.6984 12.9001 14.2984 13.9001 14.2984 14.7001V19.0001C14.2984 19.6001 13.8984 20.4001 13.3984 20.7001L11.9984 21.6001C10.6984 22.4001 8.89844 21.5001 8.89844 19.9001V14.6001C8.89844 13.9001 8.49844 13.0001 8.09844 12.5001L4.29844 8.5001C3.79844 8.0001 3.39844 7.1001 3.39844 6.5001V4.2001C3.39844 3.0001 4.29844 2.1001 5.39844 2.1001Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button v-if="selectedDocuments.length" @click.stop @click="downloadFiles(selectedDocuments)">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9 11V17L11 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 17L7 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
      <button>
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 7L17 17M7 17L17 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
      </button>
    </nav>
    <MyModal :width="'700px'" :height="'634px'" :className="'date-filter'" :isOpen="isDateFilterModalOpen" :toggleModal="(v: boolean) => isDateFilterModalOpen = v" :zIndex="100">
      <h1>Filtro por data</h1>
      <div class="dates">
        <section class="to">
          <h1><strong>De</strong> (data inicial)</h1>
          <MyDatePicker :min="{ day: 1, month: 1, year: 2024 }" :name="'initial'" :setValue="(date) => selectedDatesFilter.from = date"/>
        </section>
        <section class="from">
          <h1><strong>Até</strong> (data final)</h1>
          <MyDatePicker :min="{ day: 1, month: 1, year: 2024 }" :name="'final'" :setValue="(date) => selectedDatesFilter.to = date"/>
        </section>
      </div>
      <div class="action">
        <input type="button" value="Filtrar" @click="filter.from = selectedDatesFilter.from; filter.to = selectedDatesFilter.to; getDocuments(); isDateFilterModalOpen = false;">
        <input type="button" value="Cancelar" @click="isDateFilterModalOpen = false;">
      </div>
    </MyModal>
    <MyModal :width="'554px'" :className="'global-filters'" :isOpen="isGlobalFiltersModalOpen" :toggleModal="(v: boolean) => isGlobalFiltersModalOpen = v" :zIndex="100">
      <h1>Filtros</h1>
      <div class="filters">
        <h2>Por status</h2>
        <section>
          <label :for="status" v-for="status in DocumentStatusEnum" :key="status">
            <input type="radio" name="by-stats" :id="status" :value="status" v-model="filter.status">
            <span></span>
            <p>{{ documentStatusTranslations[status] }}</p>
          </label>
          <label for="exception">
            <input type="radio" name="by-stats" id="exception" value="exception" v-model="filter.status">
            <span></span>
            <p>Exceção</p>
          </label>
        </section>
        <h2>Por setor</h2>
        <section v-if="organizations">
          <label v-for="org in organizations" :for="org.name" :key="org.id">
            <input type="radio" name="organization_id" :value="org.id" :id="org.name" v-model="filter.organization_id">
            <span></span>
            <p>{{ org.name }}</p>
          </label>
        </section>
        <SpinnerLoader v-else />
        <h2>Por responsável</h2>
        <section v-if="users && users.length">
          <label v-for="user in users" :for="user.name + user.id" :key="user.id">
            <input type="radio" name="by-responsible" :value="user.id" :id="user.name + user.id" v-model="filter.responsible_id">
            <span></span>
            <p>{{ user.name }}</p>
          </label>
        </section>
        <SpinnerLoader v-else-if="!users" />
        <section class="no-responsibles" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 22L21 21" stroke="#949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>Nenhum responsável encontrado</p>
        </section>
        <h2>Por assunto</h2>
        <section v-if="documentSubjects">
          <label v-for="subject in documentSubjects" :for="subject.name" :key="subject.id">
            <input type="radio" name="document_subject_id" :value="subject.id" :id="subject.name" v-model="filter.document_subject_id">
            <span></span>
            <p>{{ subject.name }}</p>
          </label>
        </section>
      </div>
      <div class="action">
        <input type="button" value="Filtrar" @click="getDocuments(); isGlobalFiltersModalOpen = false;">
        <input type="button" value="Limpar" @click="filter = {}; getDocuments(); isGlobalFiltersModalOpen = false;">
      </div>
    </MyModal>
    <MyModal v-if="selectedDocument" :width="'330px !important'" :height="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime() ? '517px !important' : '299px !important'" :isOpen="isConfirmCompleteDocumentModal" :class-name="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime() ? 'confirm-complete-document picker' : 'confirm-complete-document'" :toggleModal="(v: boolean) => isConfirmCompleteDocumentModal = v" :zIndex="100">
      <div>
        <svg v-if='isSelectedDocumentOpen || !(!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime())' xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
          <path d="M33.7569 42.6831C29.1852 43.0081 29.1852 49.6381 33.7569 49.9631H44.6119C45.9335 49.9631 47.1902 49.4865 48.1652 48.5981C51.3719 45.8031 49.6602 40.1698 45.4352 39.6498C43.9185 30.5065 30.6802 33.9731 33.8219 42.6831" fill="#0071CF"/>
          <path d="M33.7569 42.6831C29.1852 43.0081 29.1852 49.6381 33.7569 49.9631H44.6119C45.9335 49.9631 47.1902 49.4865 48.1652 48.5981C51.3719 45.8031 49.6602 40.1698 45.4352 39.6498C43.9185 30.5065 30.6802 33.9731 33.8219 42.6831" fill="#0071CF"/>
          <path d="M47.6673 23.9847V33.2363C47.6673 33.9297 46.5623 34.233 46.0856 33.7347C45.0673 32.673 43.789 31.8713 42.3157 31.4163C38.914 30.3547 34.9923 31.3947 32.5223 33.9513C30.9623 35.6197 30.1173 37.6997 30.074 39.953C30.074 40.343 29.879 40.6897 29.5756 40.928C28.0156 42.163 27.0623 44.0913 27.0623 46.3013V46.323C27.0623 47.0163 26.564 47.6447 25.8706 47.6447H15.904C9.53398 47.6663 4.33398 42.4663 4.33398 36.0747V13.9097C4.33398 8.62301 8.62398 4.33301 13.9107 4.33301H18.9373C22.469 4.33301 23.574 5.48134 24.9823 7.36634L28.0156 11.3963C28.6873 12.2847 28.774 12.393 30.0306 12.393H36.0756C41.2323 12.393 45.609 15.773 47.104 20.453C47.429 21.428 47.6023 22.4247 47.6456 23.4863C47.6673 23.638 47.6673 23.833 47.6673 23.9847Z" fill="#0071CF"/>
        </svg>
        <h1 :style="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime() ? 'justify-content: space-between;' : ''">
          {{ !isSelectedDocumentOpen ? 'Reabrir' : 'Concluir'}} processo
          <figure @click="isConfirmCompleteDocumentModal = false" v-if="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_198_1747)">
                <path d="M9.29404 7.89904L15.5 1.68604C15.6857 1.50039 15.79 1.24859 15.79 0.986043C15.79 0.723492 15.6857 0.471695 15.5 0.286043C15.3144 0.100392 15.0626 -0.00390625 14.8 -0.00390625C14.5375 -0.00390625 14.2857 0.100392 14.1 0.286043L7.90004 6.49904L1.68604 0.289043C1.50039 0.103392 1.24859 -0.00090625 0.986043 -0.00090625C0.723492 -0.00090625 0.471695 0.103392 0.286043 0.289043C0.100392 0.474695 -0.00390625 0.726492 -0.00390625 0.989043C-0.00390625 1.25159 0.100392 1.50339 0.286043 1.68904L6.50004 7.89904L0.289043 14.107C0.103392 14.2927 -0.00090625 14.5445 -0.00090625 14.807C-0.00090625 15.0696 0.103392 15.3214 0.289043 15.507C0.474695 15.6927 0.726492 15.797 0.989043 15.797C1.25159 15.797 1.50339 15.6927 1.68904 15.507L7.90004 9.29304L14.108 15.499C14.2937 15.6847 14.5455 15.789 14.808 15.789C15.0706 15.789 15.3224 15.6847 15.508 15.499C15.6937 15.3134 15.798 15.0616 15.798 14.799C15.798 14.5365 15.6937 14.2847 15.508 14.099L9.29404 7.89904Z"/>
                </g>
                <defs>
                <clipPath id="clip0_198_1747">
                <rect width="15.794" height="15.793" fill="white"/>
                </clipPath>
                </defs>
            </svg>
          </figure>
        </h1>
        <h2 :style="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime() ? 'text-align: start;' : ''">Quer mesmo {{ !isSelectedDocumentOpen ? 'reabrir' : 'concluir'}} esse processo? <br>
          {{ isSelectedDocumentOpen ? 'Após conclusão só será possível novas interações se o documento for reaberto.' : '' }}  {{!(!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime()) && !isSelectedDocumentOpen ? 'Após conclusão já será possível ser feita novas interações.' : '' }}</h2>
        <div v-if="!isSelectedDocumentOpen && new Date(selectedDocument.limit_date).getTime() < new Date().getTime()" class="dates">
          <MyDatePicker :min="{ day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() }" :setValue="(v) => updatePayload.limit_date = v" name="limit" />
        </div>
        <section>
          <button v-if="!isUpdatingDocument" @click="updateDocument(!isSelectedDocumentOpen ? DocumentStatusEnum.UNDER_REVIEW : DocumentStatusEnum.COMPLETED)">Sim</button>
          <SpinnerLoaderButton v-else :isButton='false' />
          <input @click="isConfirmCompleteDocumentModal = false;" type="button" value="Cancelar">
        </section>
      </div>
    </MyModal>
    <MyModal :width="'776px'" :height="'1171px'" :isOpen="isTransferDocumentModalOpen" :class-name="'transfer-document'" :toggleModal="(v: boolean) => isTransferDocumentModalOpen = v" :zIndex="100">
      <div class="transfer">
        <h1>Transferir documento</h1>
        <h1>Setor <strong>(obrigatório)</strong></h1>
        <h2>Qual setor agora precisa ter acesso a esse documento?</h2>
        <MyOptions :currentValue="updatePayload.organization?.name ?? 'Selecione'">
          <div class="item" v-for="(organization, index) in organizations" :key="index">
            <input type="radio" :id="'organizations-transfer-modal-' + index" name="organizations-transfer-modal" :value="organization" v-model="updatePayload.organization" @change="($event.target as HTMLInputElement).checked ? (updatePayload.responsible = undefined) : ''">
            <label :for="'organizations-transfer-modal-' + index">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                  <g clip-path="url(#clip0_388_3128)">
                    <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_388_3128">
                      <rect width="12.5" height="10" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p>{{organization.name}}</p>
            </label>
          </div>
        </MyOptions>
        <h1>Responsável <strong>(obrigatório)</strong></h1>
        <h2>Qual responsável agora precisa ter acesso a esse documento?</h2>
        <MyOptions :currentValue="updatePayload?.responsible?.name ? `${updatePayload.responsible.name} ${updatePayload.responsible.last_name ?? ''}` : 'Selecione'">
          <div class="item" v-for="(responsible, index) in usersToTransfer" :key="index">
            <input type="radio" :id="'responsible-transfer-modal-' + index" name="responsible-transfer-modal" :checked="updatePayload.responsible?.id == responsible.id" :value="responsible" v-model="updatePayload.responsible"
            @change="($event.target as HTMLInputElement).checked ? (updatePayload.organization = updatePayload.responsible?.organization) : ''">
            <label :for="'responsible-transfer-modal-' + index">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                  <g clip-path="url(#clip0_388_3128)">
                    <path d="M12.3047 2.42188C12.3047 2.63021 12.2318 2.80729 12.0859 2.95312L6.42969 8.60938L5.36719 9.67188C5.22135 9.81771 5.04427 9.89062 4.83594 9.89062C4.6276 9.89062 4.45052 9.81771 4.30469 9.67188L3.24219 8.60938L0.414062 5.78125C0.268229 5.63542 0.195312 5.45833 0.195312 5.25C0.195312 5.04167 0.268229 4.86458 0.414062 4.71875L1.47656 3.65625C1.6224 3.51042 1.79948 3.4375 2.00781 3.4375C2.21615 3.4375 2.39323 3.51042 2.53906 3.65625L4.83594 5.96094L9.96094 0.828125C10.1068 0.682292 10.2839 0.609375 10.4922 0.609375C10.7005 0.609375 10.8776 0.682292 11.0234 0.828125L12.0859 1.89062C12.2318 2.03646 12.3047 2.21354 12.3047 2.42188Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_388_3128">
                      <rect width="12.5" height="10" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p>{{ responsible.name }} {{ responsible.last_name }}</p>
            </label>
          </div>
        </MyOptions>
      </div>
      <nav>
        <input type="button" value="Enviar" @click="updateDocument()" v-if="!isUpdatingDocument" :disabled="!updatePayload.responsible || !updatePayload.organization">
        <SpinnerLoaderButton v-else :height="60" :maxWidth="464"/>
        <input type="button" value="Cancelar" @click="isTransferDocumentModalOpen = false">
      </nav>
    </MyModal>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import MyModal from '@/components/modal/MyModal.vue'
import MyDatePicker from '@/components/date/MyDatePicker.vue'
import { useAxios } from '@/api/axios'
import { AxiosResponse } from 'axios'
import { UserModel } from '@/models/UserModel'
import DocumentStatusEnum, { documentStatusTranslations } from '@/enums/DocumentStatusEnum'
import { DocumentModel } from '@/models/DocumentModel'
import PaginationModel from '@/models/PaginationModel'
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import DocumentSubjectModel from '@/models/DocumentSubjectModel'
import { user } from '@/global/states/GlobalState'
import { OrganizationModel } from '@/models/OrganizationModel'
import MyOptions from '@/components/options/MyOptions.vue'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import { toast } from 'vue3-toastify'
import useDocument from '@/hooks/documents/useDocument'

const axios = useAxios()
const documents = ref<PaginationModel<DocumentModel>>()
const users = ref<UserModel[]>()
const organizations = ref<OrganizationModel[]>()
const documentSubjects = ref<DocumentSubjectModel[]>()
const selectedDatesFilter = ref({ to: '', from: '' })
const isDateFilterModalOpen = ref<boolean>(false)
const isGlobalFiltersModalOpen = ref<boolean>(false)
const isMobileOptionsFilterOpen = ref<boolean>(false)
const selectedDocuments = ref<DocumentModel[]>([])
const route = useRoute()
const filter = ref<Partial<{status: DocumentStatusEnum, responsible_id: number, document_subject_id: number, organization_id: number, from: string, to: string, search: string, order_by_created_at?: 'ASC' | 'DESC'}>>({ status: route.query.status as DocumentStatusEnum })
const selectedDocument = ref<DocumentModel>()
const isConfirmCompleteDocumentModal = ref(false)
const isTransferDocumentModalOpen = ref(false)
const isUpdatingDocument = ref(false)
const updatePayload = ref<{ responsible?: UserModel, organization?: OrganizationModel, limit_date?: string }>({})

const { getAllowedUsersToTransfer, getAbilities, isDocumentOpen } = useDocument()
const isSelectedDocumentOpen = computed(() => selectedDocument.value ? isDocumentOpen(selectedDocument.value) : false)
const selectedDocumentAbilities = computed(() => getAbilities(user.value, selectedDocument.value))
const usersToTransfer = computed(() => getAllowedUsersToTransfer(user.value, selectedDocument.value, users.value, updatePayload.value.organization))
const tab = ref<'admin' | 'others'>('admin')
const documentsFromTab = computed(() => {
  if (tab.value === 'admin') {
    return documents.value?.data.filter((document) => {
      return !!document.logs.find((log) => {
        return !!log.responsibles.find((responsible) => responsible.id === user.value.id)
      }) || document.created_by === user.value.id
    })
  } else if (tab.value === 'others') {
    return documents.value?.data.filter((document) => {
      return !document.logs.find((log) => {
        return !!log.responsibles.find((responsible) => responsible.id === user.value.id)
      }) && document.created_by !== user.value.id
    })
  }

  return []
})

const toggleOverflow = () => {
  isMobileOptionsFilterOpen.value = !isMobileOptionsFilterOpen.value
  document.body.style.overflow = isMobileOptionsFilterOpen.value ? 'hidden' : 'auto'
}

const getDocuments = (page?: number) => {
  router.push({ query: page ? { ...route.query, page } : route.query })

  documents.value = undefined
  axios.get('/api/documents', { params: { ...filter.value, page: page } })
    .then((res: AxiosResponse<PaginationModel<DocumentModel>>) => {
      documents.value = res.data
    })
}

function updateOrderFilter(event: Event) {
  const target = event.target as HTMLInputElement
  filter.value.order_by_created_at = target.value as 'ASC' | 'DESC'
  getDocuments()
}

function getDocumentSubjects () {
  axios.get('/api/document-subjects')
    .then((res: AxiosResponse<DocumentSubjectModel[]>) => {
      documentSubjects.value = res.data.sort((a, b) => a.name.localeCompare(b.name))
    })
}

function getOrganizations (): void {
  axios.get('/api/organizations', { params: filter.value })
    .then((res: AxiosResponse<OrganizationModel[]>) => {
      organizations.value = res.data.sort((a, b) => a.name.localeCompare(b.name))
    })
}

function getUsers () {
  axios.get('/api/users')
    .then((res: AxiosResponse<UserModel[]>) => {
      users.value = res.data.sort((a, b) => a.name.localeCompare(b.name))
    })
}

function downloadFiles (documents: DocumentModel[]): void {
  documents.forEach((document) => {
    document.files.forEach((file) => {
      const link = window.document.createElement('a')
      link.href = file.path.includes('data:') ? file.path : `data:${file.mime_type};base64,${file.path}`
      link.setAttribute('download', file.name)
      link.style.display = 'none'
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    })
  })
}

function getDaysDiff (firstDate: string, secondDate?: string): number {
  const date1 = new Date(firstDate)
  const date2 = secondDate ? new Date(secondDate) : new Date()

  const timeDifference = date1.getTime() - date2.getTime()

  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  const roundedDaysDifference = Math.round(daysDifference)

  return roundedDaysDifference
}

function updateDocument (status?: DocumentStatusEnum): void {
  isUpdatingDocument.value = true

  if (status) {
    axios.patch(`/api/documents/${selectedDocument.value!.id}`, { status: status, limit_date: updatePayload.value.limit_date })
      .then((res: AxiosResponse<{document: DocumentModel}>) => {
        const index = documents.value!.data.findIndex((doc) => doc.id === res.data.document.id)
        if (index !== -1) {
          documents.value!.data[index] = res.data.document
        }
        selectedDocument.value = res.data.document
      }).finally(() => {
        isConfirmCompleteDocumentModal.value = false
        isUpdatingDocument.value = false
        toast.success(`Documento ${status == DocumentStatusEnum.UNDER_REVIEW ? 'reaberto' : 'finalizado'}`)
      })
  } else {
    const payload = updatePayload.value.responsible ? { responsible_ids: [updatePayload.value.responsible.id], organization_ids: [updatePayload.value.organization!.id] } : updatePayload.value
    axios.patch(`/api/documents/${selectedDocument.value!.id}`, payload)
      .then((res: AxiosResponse<{document: DocumentModel}>) => {
        const index = documents.value!.data.findIndex((doc) => doc.id === res.data.document.id)
        if (index !== -1) {
          documents.value!.data[index] = res.data.document
        }
        selectedDocument.value = res.data.document
        updatePayload.value = {}
      }).finally(() => {
        isTransferDocumentModalOpen.value = false
        isUpdatingDocument.value = false
        toast.success('Documento transferido')
      })
  }
}

onMounted(() => {
  getOrganizations()
  getUsers()
  getDocuments(route.query.page ? parseInt(route.query.page as string) : 0)
})

watch(isGlobalFiltersModalOpen, (open) => {
  if (open && !documentSubjects.value) {
    getDocumentSubjects()
  }
})
</script>

<style lang="scss">
  .manage-documents{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 32px 0 0 0;
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
      margin: 0 0 48px 0;
      .search{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        input{
            width: 100%;
            height: 55px;
            border: none;
            border-radius: 16px;
            background: #F5F5F5;
            color: $fluxoo-primary-color;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 20px 56px 20px 20px;
            box-sizing: border-box;
            transition: background .2s;
            &:focus{
                outline: none;
                &::placeholder{
                  color: transparent
                }
                & ~ svg{
                  stroke: $fluxoo-primary-color;
                }
            }
            &::placeholder{
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            &:read-only{
                cursor: default !important;
            }
        }
        svg{
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
            position: absolute;
            right: 20px;
            stroke: #090909;
            cursor: pointer;
        }
      }
      .filters{
        display: flex;
        align-items: center;
        column-gap: 15px;
        button{
          width: 50px;
          height: 50px;
          border-radius: 12px;
          border: 1px solid #D5D5D5;
          transition: border-color .2s;
          cursor: pointer;
          &:hover{
            border-color: $fluxoo-primary-color;
            outline: 1px solid $fluxoo-primary-color;
            svg{
              stroke: $fluxoo-primary-color;
            }
          }
          figure{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              stroke: #090909;
              transition: stroke .2s;
            }
          }
          &:nth-child(1)::before{
            content: 'Filtrar por data';
            width: 120px;
          }
          &:nth-child(2)::before{
            content: 'Novo documento';
            width: 132px;
          }
          &:nth-child(3)::before{
            content: 'Filtros gerais';
            width: 105px;
          }
          &:nth-child(4)::before{
            content: 'Baixar anexos';
            width: 112px;
            right: -2px;
          }
        }
      }
    }
    .order{
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin: 0 0 48px 0;
      &-filter{
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin: -9px 0 0 0;
        input{
          display: none;
          &#order-filter-asc:checked ~ .asc{
            border-color: $fluxoo-primary-color;
            background: $fluxoo-primary-color;
            color: #FFF;
          }
          &#order-filter-desc:checked ~ .desc{
            border-color: $fluxoo-primary-color;
            background: $fluxoo-primary-color;
            color: #FFF;
          }
        }
        label{
          height: 37px;
          border-radius: 50px;
          border: 2px solid #6F6F6F;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6F6F6F;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 0 15px;
          transition: border-color .2s, color .2s, background .2s;
          cursor: pointer;
          &:hover{
            border-color: $fluxoo-primary-color;
            color: $fluxoo-primary-color;
          }
        }
      }
    }
    .tab{
      min-height: 28px;
      display: flex;
      flex-direction: row;
      align-items: start;
      column-gap: 32px;
      padding: 0;
      position: relative;
      span{
        display: flex;
        height: 3px;
        width: 123px;
        background: $fluxoo-primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: left .2s;
      }
      h1{
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        cursor: pointer;
        transition: color .2s, margin .2s;
        &:hover{
          color: $fluxoo-primary-color;
        }
      }
      &.admin{
        h1:nth-child(1){
          color: $fluxoo-primary-color;
          cursor: default;
        }
        span{
          left: 0;
        }
      }
      &.others{
        h1:nth-child(2){
          color: $fluxoo-primary-color;
          cursor: default;
        }
        span{
          width: 127px;
          left: 155px;
        }
      }
    }
    .documents{
      box-sizing: border-box;
      width: 100%;
      margin: 0 0 220px 0;
      &-title{
        width: 100%;
        margin: 0 0 15px 0;
      }
      &-list{
        width: 100%;
        height: 120px;
        border-radius: 28px;
        border: 1px solid #D3D3D3;
        background: #FFF;
        margin: 0 0 10px 0;
        animation: enterContent ease-in-out .6s;
      }
      &-full{
        display: flex;
        flex-direction: column;
        align-items: start;
        border-radius: 24px;
        border: 2px solid $fluxoo-primary-color;
        background: #F5F8F7;
        padding: 20px 24px;
        margin: 0 0 24px 0;
        position: relative;
        animation: enterContent ease-in-out .6s;
        h1{
          color: #292F31;
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 0 4px 0;
          text-align: start;
        }
        h2{
          color: #949494;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 0 24px 0;
          text-align: start;
          strong{
            color: #292F31;
          }
        }
        .close{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          stroke: $fluxoo-primary-color;
          transition: stroke .2s;
          position: absolute;
          top: 20px;
          right: 24px;
          cursor: pointer;
          &:hover{
            stroke: $fluxoo-primary-color-hover;
          }
        }
        .actions{
          width: 100%;
          max-width: 750px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div{
            display: flex;
            align-items: center;
            column-gap: 6px;
            cursor: pointer;
            &:hover{
              p{
                color: $fluxoo-primary-color;
              }
              svg{
                stroke: $fluxoo-primary-color;
                fill: $fluxoo-primary-color;
              }
            }
            p{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
              transition: color .2s;
            }
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-width: 24px;
              stroke: #292D32;
              fill: #292D32;
              transition: stroke .2s, fill .2s;
            }
            &:nth-child(3){
              svg{
                width: 12px;
                min-width: 12px;
                height: 10px;
                min-height: 12px;
              }
            }
            &:nth-child(4){
              svg{
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
              }
            }
          }
        }
      }
      .doc-grid{
        display: grid !important;
        grid-template-columns: 24px 1fr 100px 100px 140px 100px 60px;
        column-gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        box-sizing: border-box;
        label{
          display: flex;
          flex-direction: column;
          align-items: start;
          h1{
            color: #090909;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          h2{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #6F6F6F;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: start;
            margin: 6px 0 0 0;
            svg{
              min-width: 20px;
              fill: #090909;
              transition: fill .2s;
              cursor: pointer;
              &:hover{
                fill: $fluxoo-primary-color;
              }
            }
          }
          &.title{
            h1{
              -webkit-line-clamp: 2;
            }
          }
          &.check{
            input{
              display: none;
              &:checked ~ span{
                background: $fluxoo-primary-color;
                border-color: $fluxoo-primary-color;
              }
            }
            span{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              border-radius: 8px;
              border: 1px solid #6F6F6F;
              transition: border-color .2s, background .2s;
              cursor: pointer;
            }
          }
          &.date{
            h1{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              &.text-red{
                color: #EB1320
              }
            }
            .tag{
              margin: 4px 0 0 0;
            }
            &.late{
              h1{
                color: #EB1320;
              }
              .tag{
                display: none;
              }
            }
          }
          &.actions{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            row-gap: 20px;
            button{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              cursor: pointer;
              figure{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
              }
              &:hover{
                svg{
                  stroke: $fluxoo-primary-color;
                }
              }
              &:nth-child(1)::before{
                content: 'Visualizar';
                width: 85px;
              }
              /*&:nth-child(2)::before{
                content: 'Interagir';
                width: 75px;
              }*/
            }
            .options{
              position: relative;
              &:focus-within{
                section{
                  display: flex;
                  animation: enterContent ease-in-out .6s;
                  z-index:10
                }
              }
              section{
                box-sizing: border-box;
                width: 235px;
                display: none;
                flex-direction: column;
                padding: 24px;
                border-radius: 16px;
                background: #FFF;
                box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
                position: absolute;
                top: calc(100% + 15px);
                right: -4px;
                row-gap: 24px;
                div{
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  svg{
                    stroke: black !important;
                    fill: black !important;
                  }
                  &#confirm{
                    svg{
                      width: 12px;
                      min-width: 12px;
                      height: 10px;
                      min-height: 12px;
                      margin: 0 3px 0 0;
                    }
                  }
                  svg{
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    min-height: 20px;
                  }
                  &:hover{
                    p{
                      color: $fluxoo-primary-color;
                    }
                    svg{
                      stroke: $fluxoo-primary-color !important;
                      fill: $fluxoo-primary-color !important;
                    }
                  }
                  p{
                    color: #090909;
                    font-family: 'Inter', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0;
                    transition: color .2s;
                  }
                }
              }
            }
            svg{
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              stroke: #090909;
              fill: #090909;
              transition: stroke .2s, fill .2s;
              cursor: pointer;
              &:hover{
                fill: $fluxoo-primary-color;
              }
            }
          }
          &.exceptions{
            .exception{
              display: flex;
              align-items: center;
            }
          }
          .exception{
            display: none;
            color: #E1793E;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            column-gap: 4px;
            text-transform: uppercase;
            margin: 4px 0 0 0;
            svg{
              width: 16px;
              min-width: 16px;
              height: 16px;
              min-height: 16px;
            }
          }
          .tag{
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 60px;
            padding: 4px 10px 5px;
            &.under_review{
              background: #4F1AC0;
              color: #FFF;
            }
            &.completed{
              background: #079F72;
              color: #FFF;
            }
            &.overdue{
              background: #EB1320;
              color: #FFF;
            }
            &.extended{
              background: #FBE83F;
              color: #080808;
            }
            &.pending {
              background: #EB9C3F;
              color: #FFF;
            }
            &.exceptions{
              background: #2B7CD3;
              color: #FFF;
            }
            &.black{
              background: var(--preto-principal, #292F31);
              color: #fff
            }
          }
        }
      }
    }
    .no-documents{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 50px 0 0 0;
      h1{
        color: #080808;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 13px 0 0 0;
        text-align: center;
      }
      p{
        color: #080808;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 8px 0 0 0;
        a{
          cursor: pointer;
          color: $fluxoo-primary-color;
          text-decoration-line: underline;
        }
      }
      svg{
        width: 173px;
        min-width: 173px;
        height: 173px;
        min-height: 173px;
      }
    }
    .action-filters{
      display: none;
    }
    .modal{
      &.date-filter{
        padding: 0 15px;
        .modal__content{
          padding: 32px;
          box-sizing: border-box;
          overflow-y: auto;
          &::-webkit-scrollbar{
            width: 0;
          }
          button{
            display: flex;
            top: 44px;
            right: 32px;
          }
          h1{
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: start;
            margin: 0 0 25px 0;
          }
          .dates{
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            column-gap: 20px;
            margin: 0 0 auto 0;
            h1{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0 0 25px 0;
              strong{
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
            section{
              width: 100%;
              max-width: 280px;
              display: flex;
              flex-direction: column;
            }
          }
          .action{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            column-gap: 20px;
            margin: 25px 0 0 0;
            input{
              border: none;
              cursor: pointer;
              &:first-child{
                width: 100%;
                max-width: 280px;
                height: 60px;
                border-radius: 30px;
                background: $fluxoo-primary-color;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                transition: background .2s;
                &:hover{
                  background: $fluxoo-primary-color-hover;
                }
              }
              &:last-child{
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: unset;
                transition: color .2s;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
            }
          }
        }
      }
      &.global-filters{
        padding: 0;
        .modal__content{
          border-radius: 0;
          margin: 0 0 0 auto;
          padding: 32px 0 0;
          row-gap: 50px;
          overflow-y: auto;
          &::-webkit-scrollbar{
            width: 0;
          }
          &.closed{
            transform: translateY(0);
          }
          h1{
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            text-align: start;
            padding: 0 32px;
          }
          button{
            display: flex;
            top: 37px;
            right: 32px;
          }
          .filters{
            display: flex;
            flex-direction: column;
            align-items: start;
            row-gap: 16px;
            padding: 0 32px;
            h2{
              color: #575757;
              font-family: 'Inter', sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin: 53px 0 0 0;
              &:first-child{
                margin: 0;
              }
            }
            section{
              display: flex;
              flex-wrap: wrap;
              column-gap: 24px;
              row-gap: 16px;
              &.no-responsibles{
                gap:0.5rem;
                align-items:center;
                p{
                  margin:0 !important;
                  color: #949494;
                  font-family: 'Inter', sans-serif;
                  font-size: 16px;
                }
              }
              label{
                display: flex;
                align-items: center;
                column-gap: 8px;
                cursor: pointer;
                input{
                  display: none;
                  &:checked{
                    & ~ span{
                      border-color: $fluxoo-primary-color;
                      outline: 1px solid $fluxoo-primary-color;
                      &::before{
                        transform: scale(1.0);
                        opacity: 1;
                      }
                    }
                    & ~ p{
                      color: $fluxoo-primary-color;
                    }
                  }
                }
                span{
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                  min-height: 24px;
                  border-radius: 50%;
                  border: 1px solid #D5D5D5;
                  position: relative;
                  transition: border-color .2s;
                  &::before{
                    content: '';
                    width: 12px;
                    min-width: 12px;
                    height: 12px;
                    min-height: 12px;
                    border-radius: 50%;
                    background: $fluxoo-primary-color;
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    transform: scale(0.0);
                    opacity: 0;
                    transition: transform .2s, opacity .1s;
                  }
                }
                p{
                  color: #949494;
                  font-family: 'Inter', sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 17px;
                  margin: 0;
                  transition: color .2s;
                }
              }
            }
          }
          .action{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 32px;
            border-radius: 16px;
            background: #FFF;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            position: sticky;
            right: 0;
            bottom: 0;
            column-gap: 20px;
            input{
              border: none;
              cursor: pointer;
              &:first-child{
                width: 100%;
                max-width: 220px;
                height: 60px;
                border-radius: 30px;
                background: $fluxoo-primary-color;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                transition: background .2s;
                &:hover{
                  background: $fluxoo-primary-color-hover;
                }
              }
              &:last-child{
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: unset;
                transition: color .2s;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
            }
          }
        }
      }
      &.confirm-complete-document{
        &.picker{
          .modal__content{
            button{
              display: none;
            }
            div{
              align-items: start;
              padding: 19px 15px 24px;
              .close-modal{
                display: flex;
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                position: absolute;
                top: 28px;
                right: 23px;
                cursor: pointer;
                figure{
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0;
                  svg{
                      width: 16px;
                      min-width: 16px;
                      height: 16px;
                      min-height: 16px;
                      fill: #090909;
                      transition: fill .2s;
                      &:hover{
                          fill: $fluxoo-primary-color;
                      }
                  }
                }
              }
              section{
                button{
                  display: flex;
                }
              }
            }
          }
        }
        .modal__content{
          button ~ div{
            overflow-y: auto;
            &::-webkit-scrollbar{
              width: 0;
            }
          }
          div{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 26px 15px 24px;
            box-sizing: border-box;
            h1{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #090909;
              font-family: 'Nunito', sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 900;
              line-height: normal;
              margin: 2px 0 7px 0;
              figure{
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1px 0 0 0;
                cursor: pointer;
                svg{
                  fill: #090909;
                  transition: fill .2s;
                  &:hover{
                    fill: $fluxoo-primary-color;
                  }
                }
              }
            }
            h2{
              color: #6F6F6F;
              text-align: center;
              font-family: 'Inter', sans-serif;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0;
            }
            section{
              width: 100%;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: space-between;
              padding: 0;
              margin: auto 0 0 0;
              button{
                height: 30px;
                width: 80px;
                top: unset;
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: color .2s;
                cursor: pointer;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
              input{
                height: 60px;
                border-radius: 30px;
                background: #090909;
                color: #FFF;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: none;
                width: 173px;
                cursor: pointer;
              }
            }
            .dates{
              padding: 15px 0;
              min-height: 330px;
              margin: 0 auto 10px;
              .date-picker{
                max-width: 290px;
                padding: 0;
                .select-date{
                  display: flex !important;
                  flex-direction: row !important;
                  padding: 0;
                  .mySelect{
                    &.open{
                      div{
                        display: flex;
                      }
                    }
                    div{
                      height: unset;
                      display: none;
                      padding: 5px 10px 5px 0;
                    }
                  }
                }
                .select-day{
                  padding: 0;
                  .date{
                    display: grid;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        .close-modal{
          display: flex;
          top: 28px;
          right: 28px;
        }
      }
      &.transfer-document{
        padding: 15px;
        .modal__content{
          position: relative;
          padding: 32px 32px 0;
          box-sizing: border-box;
          overflow-y: auto;
          margin: 0;
          &::-webkit-scrollbar{
            width: 0;
          }
          .transfer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            h1{
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              margin: 0;
              strong{
                color: #090909;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              &:first-child{
                color: #090909;
                font-family: 'Nunito', sans-serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                margin: 0 0 42px 0;
              }
            }
            h2{
              color: #6F6F6F;
              font-family: 'Inter', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 0 0 13px 0;
            }
            .option{
              &:nth-child(4){
                margin: 0 0 28px 0;
              }
              &:last-child{
                margin: 0 0 40px 0;
              }
              .top{
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
            .text-editor{
              border: 1px solid #D3D3D3;
              margin: 0;
              &__menu-bar{
                flex-direction: row;
                box-sizing: border-box;
              }
              &--input{
                div{
                  box-sizing: border-box;
                }
              }
              &::before{
                content: unset;
              }
              &::after{
                content: unset;
              }
            }
            .file-document{
              width: 100%;
              margin: 0 0 40px 0;
            }
          }
          nav{
            width: 100%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 32px;
            border-radius: 16px;
            background: #FFF;
            box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            position: sticky;
            bottom: 5px;
            column-gap: 20px;
            margin: auto 0 0 1px;
            input{
              border: none;
              cursor: pointer;
              &:first-child{
                width: 100%;
                max-width: 464px;
                height: 60px;
                border-radius: 30px;
                background: $fluxoo-primary-color;
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                transition: background .2s;
                &:hover{
                  background: $fluxoo-primary-color-hover;
                }
              }
              &:last-child{
                color: #737373;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: unset;
                transition: color .2s;
                &:hover{
                  color: $fluxoo-primary-color;
                }
              }
            }
          }
        }
        .close-modal{
          display: flex;
          top: 41px;
          right: 32px;
        }
      }
    }
    input[type="text"]{
      &:focus{
        border: none !important;
      }
    }
  }

  @media(max-width: 1025px){
    .manage-documents{
      .documents{
        .doc-grid{
          padding: 0 10px;
          column-gap: 10px;
          grid-template-columns: 24px 30% 100px 100px 140px 100px 60px;
        }
      }
    }
  }

  @media(max-width: 950px){
    .manage-documents{
      .documents{
        .doc-grid{
          padding: 0 10px;
          column-gap: 10px;
          grid-template-columns: 24px 25% 100px 100px 140px 100px 60px;
        }
      }
    }
  }

  @media(max-width: 890px){
    .manage-documents{
      .documents{
        .doc-grid{
          padding: 0 10px;
          column-gap: 10px;
          grid-template-columns: 24px 20% 100px 100px 140px 100px 60px;
        }
      }
    }
  }

  @media(max-width: 850px){
    .manage-documents{
      .documents{
        margin: 0 0 115px 0;
        .documents-title{
          label{
            display: none;
            &:first-child{
              display: flex;
              input:checked ~ span::after{
                color: $fluxoo-primary-color;
              }
              span{
                position: relative;
                &::after{
                  content: 'Titulo e Origem';
                  color: #090909;
                  font-family: 'Inter', sans-serif;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 25px;
                  white-space: nowrap;
                  position: absolute;
                  left: calc(100% + 10px);
                  transition: color .2s;
                }
              }
            }
          }
        }
        .documents-list{
          padding: 24px;
          height: 292px;
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 15px;
          grid-template-areas:
          "check actions"
          "info info"
          "id date"
          "name stats";
          justify-content: space-between;
          animation: enterContentGrid ease-in-out .6s;
          label{
            h2{
              margin: 0;
            }
            &:nth-child(1){
              grid-area: check;
            }
            &:nth-child(2){
              grid-area: info;
              justify-content: center;
            }
            &:nth-child(3){
              grid-area: id;
              height: 47px;
              justify-content: center;
            }
            &:nth-child(4){
              grid-area: date;
              margin: 0 0 0 auto;
              height: 47px;
              justify-content: center;
            }
            &:nth-child(5){
              grid-area: name;
              height: 20px;
              justify-content: center;
            }
            &:nth-child(6){
              margin: 0 0 0 auto;
              height: 20px;
              justify-content: center;
            }
            &:nth-child(7){
              grid-area: actions;
              max-width: 60px;
              margin: 0 0 0 auto;
            }
            .exception{
              display: flex;
              position: absolute;
              top: 36px;
              left: 58px;
              margin: 0;
            }
          }
        }
        .documents-full{
          .actions{
            flex-wrap: wrap;
            align-items: start;
            justify-content: start;
            gap: 20px;
          }
        }
      }
    }
  }

  @media(max-width: 768px){
    .manage-documents{
      .header{
        .filters{
          display: none;
        }
      }
      .action-filters{
        display: flex;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        row-gap: 8px;
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 4;
        box-sizing: border-box;
        transition: opacity 0.3s;
        &.active{
          width: 100vw;
          height: 100dvh;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: end;
          row-gap: 8px;
          position: fixed;
          background: #292F3150;
          top: 0;
          bottom: unset;
          right: unset;
          left: 0;
          z-index: 4;
          padding: 15px;
          box-sizing: border-box;
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            animation: enterContent ease .4s;
            &:last-child{
              figure{
                svg{
                  transform: rotate(0);
                }
              }
            }
          }
        }
        button{
          display: none;
          width: 45px;
          min-width: 45px;
          height: 45px;
          min-height: 45px;
          background: #FFF;
          border-radius: 50%;
          transition: background .2s;
          filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.15));
          cursor: pointer;
          &:hover{
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
              }
            }
          }
          figure{
            widows: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            svg{
              width: 20px;
              min-width: 20px;
              height: 20px;
              min-height: 20px;
              stroke: #090909;
              transition: stroke .2s;
            }
          }
          &:last-child{
            display: flex;
            align-items: center;
            justify-content: center;
            background: $fluxoo-primary-color;
            figure{
              svg{
                stroke: #FFF;
                margin: 0 0 0 -1px;
                transform: rotate(-405deg);
                transition: transform .2s, background .2s;
              }
            }
            &:hover{
              background: $fluxoo-primary-color-hover;
            }
          }
        }
      }
    }
  }

  @media(max-width: 680px){
    .manage-documents{
      .modal{
        &.date-filter{
          padding: 15px;
          .modal__content{
            max-width: 400px !important;
            .dates{
              flex-direction: column;
              row-gap: 30px;
              section{
                max-width: unset;
                .date-picker{
                  div{
                    justify-content: space-between;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media(max-width: 680px){
    .manage-documents{
      .order{
        flex-direction: column;
        margin: 0 auto 48px 0;
      }
      .tab{
        margin: 0 auto 50px 0;
      }
    }
  }

  @media(max-width: 425px){
    .manage-documents{
      .action-filters{
        bottom: 15px;
        &.active{
          padding-bottom: 15px;
        }
      }
      .modal {
        padding: 0 15px;
        &__content{
          border-radius: 28px;
          max-width: unset !important;
          max-height: unset !important;
          bottom: unset;
          left: unset;
          right: unset;
          top: 0;
          opacity: 0;
          transition: all 0.2s ease-out;
          &.closed {
              opacity: 0;
              transform: translateY(-110%) !important;
          }
          &.open {
              opacity: 1;
              transform: translateY(0) !important;
          }
        }
        &.transfer-document{
          padding: 0;
          .modal__content{
            border-radius: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            nav{
              animation: enterToBottom ease 0.9s;
              width: 100vw;
              position: fixed;
              bottom: 0;
              border-radius: 20px 20px 0px 0px;
              background: #FFF;
              box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
              padding: 15px;
              box-sizing: border-box;
              z-index: 3;
              margin: 0;
            }
            .close-modal{
              display: flex;
              top: 38px;
              right: 15px;
            }
            .transfer{
              padding: 32px 15px 0;
              box-sizing: border-box;
              h1{
                &:first-child{
                  font-size: 24px;
                }
              }
            }
          }
        }
        &.confirm-complete-document{
          &.picker{
            padding: 10px;
            .modal__content{
              div{
                padding: 19px 10px 24px;
                .dates{
                  padding: 15px 0;
                  .date-picker{
                    .select-day{
                      .date{
                        gap: 2px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal{
        &.date-filter{
          padding: 0;
          .modal__content{
            max-width: unset !important;
            padding: 32px 15px;
            button{
              right: 15px;
            }
          }
        }
        &.global-filters{
          .modal__content{
            h1{
              padding: 0 15px;
            }
            button{
              right: 25px;
            }
            .filters{
              padding: 0 15px;
            }
            .action{
              padding: 15px;
            }
          }
        }
      }
    }
  }
</style>
